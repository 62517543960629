<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex flex-wrap bg-primary-50 text-center mb-8 px-4 py-4">
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ alarms_count }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.number_of_alarm") }}</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2" v-if="user.role == 'superuser' && customers.length > 1">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ customers_count }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.number_of_customer") }}</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ units_count }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.number_of_business") }}</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ push_button_count }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.number_of_alarm_pushb") }}</div>
        </div>
      </div>

      <div>
        <div v-if="this.user.role != 'user' && customer_info">
          <CustomerInfo type="customer" :customerInfo="customer_info" @updateAddress="customer_info = $event" />
        </div>
        <div v-if="user.role == 'superuser' && customers.length > 1">
          <div v-for="(c, i) in customers" :key="i" class="flex flex-col mb-3">
            <div class="clear-both bg-primary-50 px-4 py-3 ">
              <div class="float-left text-bold font-serif">
                {{ c.name }}
              </div>
              <div class="float-right">
                <router-link tag="button" :to="'/verksamheter/customer/' + c.customer_id + '/-'" class="btn-blue-outline font-bold mx-2">
                  {{ $t("dashboard_seller.activity") }}
                  <BaseIcon icon="users" class="ml-1" />
                </router-link>
                <router-link tag="button" :to="'/addon-activate/customer/' + c.customer_id" class="btn-blue-outline font-bold mx-2">
                  {{ $t("dashboard_seller.additional_services") }}
                  <BaseIcon icon="cube" class="ml-1" />
                </router-link>
                <button class="btn-blue-outline font-bold mx-2" @click="showCustomerUpdateForm(c)">
                  {{ $t("dashboard_seller.change") }}
                  <BaseIcon icon="pencil-alt" class="ml-1" />
                </button>
              </div>
            </div>
          </div>

          <UpdateCustomerInformation type="customer" :customerInfo="updateInfo" @updateAddress="getDashboard" />
        </div>
      </div>
      <div class="flex flex-wrap text-center justify-center lg:justify-start mt-8">
        <div class="w-full md:w-1/2 lg:w-1/3 md:pr-4 mt-2">
          <router-link tag="div" :to="'/mina-avtal/customer/' + customer_id" class="text-white font-serif bg-primary-300 py-12 font-bold shadow hover:shadow-lg hover:bg-primary-400 rounded cursor-pointer transition duration-200">
            {{ $t("dashboard_seller.view_agreement") }}
            <BaseIcon class="text-white ml-2" icon="file-alt" />
          </router-link>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 md:pr-4 mt-2" v-if="customers.length == 1">
          <router-link tag="div" :to="'/verksamheter/customer/' + customer_id + '/-'" class="text-white font-serif bg-primary-300 py-12 font-bold shadow hover:shadow-lg hover:bg-primary-400 rounded cursor-pointer transition duration-200">
            {{ $t("dashboard_seller.view_activities") }}
            <BaseIcon class="text-white ml-2" icon="users" />
          </router-link>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 mt-2" v-if="user.role == 'user'">
          <router-link tag="div" to="/addon-activate" class="text-white font-serif bg-primary-300 py-12 font-bold shadow hover:shadow-lg hover:bg-primary-400 rounded cursor-pointer transition duration-200">
            {{ $t("dashboard_seller.view_additional_services") }}
            <BaseIcon class="text-white ml-2" icon="cube" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerInfo from "@/components/customer_info";
import UpdateCustomerInformation from "@/components/modal/customer_info_update";

export default {
  name: "Dashboard",
  components: {
    CustomerInfo,
    UpdateCustomerInformation,
  },
  title() {
    let page = this.$t("page_titles.customer");
    if (this.user.role == "user") page = this.$t("page_titles.departments");
    return page;
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      customer_id: "-",
      customers_count: "-",
      units_count: "-",
      alarms_count: "-",
      push_button_count: "-",
      customers: [],
      units: [],
      customer_info: null,
      unit_info: null,
      updateInfo: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    "$i18n.locale"() {
      let page = this.$t("page_titles.customer");
      if (this.user.role === "user") {
        page = this.$t("page_titles.departments");
      }
      document.title = page;
    },
  },
  methods: {
    getDashboard() {
      // fetch dashboard for multiple customer, multiple unit, single customer based on user setting
      let customer_id = "";
      if (this.user.role != "superuser" && this.user.role != "user" && this.id != "-") customer_id = this.id;

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/dashboard/customer/${customer_id}`)
        .then((response) => {
          this.customers = response.data.customers;
          this.units = response.data.units;

          this.customers_count = this.customers.length;
          this.units_count = response.data.units.length;

          if (this.customers.length == 1) {
            this.setPageTitle(`${this.customers[0].name} - Dashboard`, "dashboard");
            this.customer_info = this.customers[0];
            this.customer_id = this.customer_info.customer_id;
          } else this.setPageTitle(`Dashboard`, "dashboard");

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
        });
    },

    async getPushButtonCount() {
      try {
        let customer_id = "";
        if (this.user.role != "superuser" && this.user.role != "user" && this.id != "-") customer_id = this.id;

        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/gps/push-button/customer/${customer_id}`);

        this.push_button_count = response.data.push_button_count;
        this.alarms_count = response.data.alarms_count;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.handleError(error);
      }
    },

    showCustomerUpdateForm(customer) {
      this.updateInfo = _.cloneDeep(customer);
      this.$modal.show("update-address");
    },
  },

  created() {
    this.isLoading = true;
    this.getDashboard();
    this.getPushButtonCount();
  },
};
</script>
